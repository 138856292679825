import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Services from "./pages/Services";
import NewsList from "./pages/News/List";
import NewsDetail from "./pages/News/Detail";
import Products from "./pages/Products";
import Faq from "./pages/Faq";
import Downloads from "./pages/Downloads";
import CsrList from "./pages/Csr/List";
import CsrDetail from "./pages/Csr/Detail";
import InvestorRelation from "./pages/InvestorRelation";
import InvestorCK from "./pages/InvestorCK";
import About from "./pages/About";
import Teams from "./pages/Teams";
import BranchwiseOfficers from "./pages/BranchwiseOfficers";
import Calculators from "./pages/Calculators";
import ContactUs from "./pages/ContactUs";
import Calendar from "./pages/Calendar";
import ScrollTop from "./utils/ScrollTop";
import ScrollToTop from "react-scroll-to-top";
import { IoIosArrowUp } from "react-icons/io";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Branch from "./pages/Branch";
import Atm from "./pages/Atm";
import MemberNetwork from "./pages/MemberNetwork";
import Forex from "./pages/Forex";
import Career from "./pages/Career";
import Detail from "./pages/Career/Detail";
import NotFound from "./pages/NotFound";
import Saving from "./pages/Product/Saving";
import Fixed from "./pages/Product/Fixed";
import GrievanceForm from "./pages/GrievanceForm";
import GrievanceResForm from "./pages/GrievanceResForm";
import Loan from "./pages/Product/Loan";
import ReactGA from "react-ga4";
import LastVisited from "./components/LastVisited";
import MonthlyInterestRates from "./pages/MonthlyInterestRates";
import YearInterestRates from "./pages/YearInterestRates";
import NoticeList from "./pages/Notices/List";
import NoticeDetail from "./pages/Notices/Detail";
import Product from "./pages/Product";
import DigitalServicePage from "./pages/DigitalServicePage";
import Search from "./pages/Search";
import { lazy, Suspense } from "react";
import Fallback from "./utils/Fallback";
import Officers from "./pages/officers";
import CSRItems from "./pages/Csr/items";
import CSRSUbCat from "./pages/Csr/subcat";
import Achievements from "./pages/Achievements";
import Achievements_details from "./pages/Achievements/details";
import LearningDevelopment from "./pages/learning_development";
import CostFund from "./pages/CostFund";
// import FList from "./pages/Csr/flist";
// import CSRImages from "./pages/Csr/image";
const Messenger = lazy(() => import("./utils/Messenger"));
const TRACKING_ID = "G-G9XM2M4YW0";
ReactGA.initialize(TRACKING_ID);
function App() {
  return (
    <BrowserRouter>
      <ScrollTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<NewsList />} />
        <Route path="/news/:slug" element={<NewsDetail />} />
        <Route path="/notices" element={<NoticeList />} />
        <Route path="/notices/:slug" element={<NoticeDetail />} />
        <Route path="/services/:slug" element={<Services />} />
        <Route path="/products/:slug" element={<Products />} />
        <Route path="/product" element={<Product />} />
        <Route path="officers/officer/:slug" element={<Officers />} />
        <Route path="product/saving-account/:slug" element={<Saving />} />
        <Route path="product/fixed-deposit/:slug" element={<Fixed />} />
        <Route path="grievance-form" element={<GrievanceForm />} />
        <Route path="GrievanceResForm" element={<GrievanceResForm />} />
        <Route path="product/professional-loan/:slug" element={<Loan />} />
        <Route path="/investor-relation/:slug" element={<InvestorRelation />} />
        <Route path="/investor/:slug" element={<InvestorCK />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/csr" element={<CsrList />} />
        <Route path="/csr/lists/:slug" element={<CSRSUbCat />} />
        <Route path="/csr/lists/items/:slug" element={<CSRItems />} />
        <Route path="/csr/lists/items/details/:slug" element={<CsrDetail />} />
        <Route
          path="/learning_and_development"
          element={<LearningDevelopment />}
        />
        <Route path="/achievements" element={<Achievements />} />
        <Route
          path="/achievement/details/:slug"
          element={<Achievements_details />}
        />
        {/* <Route path="/csr/lists/details/:slug" element={<FList />} /> */}
        {/* <Route path="/csr/lists/details/items/:slug" element={<CSRImages />} /> */}
        <Route path="/about-us/:slug" element={<About />} />
        <Route path="/teams/:slug" element={<Teams />} />
        <Route path="/branch-officers/:slug" element={<BranchwiseOfficers />} />
        <Route path="/calculators" element={<Calculators />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/branch-location" element={<Branch />} />
        <Route path="/atm-location" element={<Atm />} />
        <Route path="/member-network" element={<MemberNetwork />} />
        <Route path="/forex" element={<Forex />} />
        <Route path="/career" element={<Career />} />
        <Route path="/career/:id" element={<Detail />} />
        <Route path="/interest-rates" element={<MonthlyInterestRates />} />
        <Route path="/cost-of-fund" element={<CostFund />} />
        <Route
          path="/fiscal-year-interest-rates"
          element={<YearInterestRates />}
        />
        <Route
          path="/digital-services/:slug"
          element={<DigitalServicePage />}
        />
        <Route path="/search" element={<Search />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <ScrollToTop
        className="scrollTopComp"
        smooth
        component={<IoIosArrowUp className="scrollIcon" fontSize={26} />}
      ></ScrollToTop>
      <Suspense fallback={<Fallback />}>
        <Messenger />
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <LastVisited />
    </BrowserRouter>
  );
}

export default App;
